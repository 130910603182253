import {
    User,
    Profile,
    Role,
    Task,
    Process,
    TaskInstance,
} from "@satys/contracts/satys/domain/domain_pb"
import Dashboard from "./dashboard"
import type { Language } from "/src/i18n"

import Status from "/src/utils/status"

const { observable } = require("/src/watcher.ls")

interface GetSetStatus {
    get: Status
    set: Status
}

type StringObject = {
    [key in string]: StringObject | string
}

export class State {
    // Language
    curr_lang: Language
    lang: StringObject

    // Current User
    user?: User
    profile?: Profile
    role?: Role
    user_tasks: Task[] = []
    tasks: TaskInstance[] = []
    profile_status: GetSetStatus = {
        get: Status.IDLE,
        set: Status.IDLE,
    }

    // gRPC
    call_metadata = {}
    processes?: Process[]

    // Indepth view given answer filter
    // Exists out of tuples with [question, answer]
    given_answer_filter = []

    // Dashboard
    dashboard: Dashboard = new Dashboard()

    // When the user hits the save as pdf button
    printMode = false
}

// Make sure we can osberve any change made to the state
const state: State = observable(new State())
export default state
