import m from "mithril"
import { MaterialDesignSpinner } from "polythene-mithril"
import { MaterialDesignSpinnerCSS } from "polythene-css"
import MithrilTsx from "/src/mithril-tsx"

interface Attrs {
    singleColor?: boolean,
    size?: "small" | "medium" | "large",
    className?: string,
    [key: string]: unknown,
}

class Spinner extends MithrilTsx<Attrs> {
    view({ attrs, children }: this["Vnode"]) {
        const { singleColor = true, size = "medium", className = "", ...other } = attrs
        return m(MaterialDesignSpinner, {
            className: `absolute-spinner ${className}`,
            permanent: true,
            singleColor: singleColor,
            size: size,
            ...other,
        }, children)
    }
}

export default Spinner

MaterialDesignSpinnerCSS.addStyle(".absolute-spinner", {
    rotation_duration: 2,
    border_width_small: 3,
    border_width_regular: 4,
    border_width_medium: 5,
    border_width_large: 6,
})
