import m from "mithril"
import { analytics } from "/src/extensions"
import MithrilTsx from "/src/mithril-tsx"
import { translate as t } from "/src/i18n"
import { Dialog, Button } from "polythene-mithril"
import { getNamespace } from "/src/utils"

import * as styles from "./index.module.sass"

const ns = getNamespace(__filename)

export interface Attrs {
    hideTitle?: boolean;
    title?: string;
    icon?: string;
    infoMessage?: JSX.Element;
    showHighlights?: boolean;
    hasFilters?: boolean;
    downloadableAsImage?: boolean;
    downloadableAsCsv?: boolean;
    downloadCardAsCsv?: () => void;
    downloadCardAsImage?: () => void;
    toggleFilters?: () => void;
}

export default class Header extends MithrilTsx<Attrs> {
    sendAnalytics = false
    openCardOptions = false
    cardOptionsMenuElement: HTMLElement
    cardOptionsIconElement: HTMLElement

    oncreate(vnode: this["VnodeDOM"]) {
        this.cardOptionsMenuElement = vnode.dom.querySelector(".card-options-menu")
        this.cardOptionsIconElement = vnode.dom.querySelector(".card-options-icon")

        window.addEventListener("click", this.hideOptionsMenuIfClickOutside.bind(this))
    }

    onremove() {
        window.removeEventListener("click", this.hideOptionsMenuIfClickOutside.bind(this))
    }

    dialogHeader = (title: string, icon: string) => {
        return title && (
            <div className="header flex flex-col items-center">
                { icon ? (
                    <i className="material-icons mr2">{ icon }</i>
                ) : <></> }
                <h3 className="mv0">{ title }</h3>
            </div>
        )
    }

    dialogBody = (text: JSX.Element) => (
        <div className="body lh-copy pre-wrap">
            { text }
        </div>
    )

    dialogFooter = () => {
        // Button throws errors when used as JSX
        return m(Button, {
            label: t("close", ns),
            events: {
                onclick: () => Dialog.hide(),
            },
        })
    }

    toggleDialog = (attrs: Attrs) => {
        Dialog.show({
            modal: false,
            title: this.dialogHeader(attrs.title, attrs.icon),
            body: this.dialogBody(attrs.infoMessage),
            footerButtons: this.dialogFooter(),
            backdrop: true,
            className: styles.card_info_dialog,
        })

        if (!this.sendAnalytics) {
            analytics.event("show_card_info_dialog", {
                "value": attrs.title,
            })
            this.sendAnalytics = true
        }
    }

    toggleCardOptions(e: MouseEvent) {
        this.openCardOptions ? this.hideCardOptions(e) : this.showCardOptions(e)
    }

    showCardOptions(e?: MouseEvent) {
        e?.stopPropagation()
        this.cardOptionsMenuElement.classList.add("show")
        this.cardOptionsIconElement.classList.add("active")
        this.openCardOptions = true
        m.redraw()
    }

    hideCardOptions(e?: MouseEvent) {
        e?.stopPropagation()
        this.cardOptionsMenuElement.classList.remove("show")
        this.cardOptionsIconElement.classList.remove("active")
        this.openCardOptions = false
        m.redraw()
    }

    hideOptionsMenuIfClickOutside(e: MouseEvent) {
        if (this.openCardOptions) {
            const target = e.target as HTMLElement
            if (!target.closest(".card-options-menu")) {
                this.hideCardOptions()
            }
        }
    }

    view({ attrs }: this["Vnode"]) {
        const {
            hideTitle = false,
            icon,
            title,
            infoMessage,
            hasFilters,
            downloadableAsImage: downloadableAsImage = false,
            downloadableAsCsv: downloadableAsCsv = false,
        } = attrs

        const shouldShowOptionsMenu = hasFilters || infoMessage || downloadableAsImage || downloadableAsCsv

        return (
            <div className="flex flex-col justify-between items-center">
                <div className="flex flex-col justify-between items-center">
                    { !hideTitle && icon ? (
                        <i className="material-icons mr2">{ icon }</i>
                    ) : <></> }
                    { !hideTitle && title ? (
                        <h3>{ title }</h3>
                    ) : <></> }
                </div>

                { shouldShowOptionsMenu ? (
                    <div class="card-options-icon noprint" onclick={(e: MouseEvent) => this.toggleCardOptions(e)}>
                        { m.trust(require("/src/assets/icons/dots_vertical.svg")) }
                        <div class="card-options-menu">
                            { hasFilters ? (
                                <div class="card-options-menu-item" onclick={attrs.toggleFilters}>
                                    { m.trust(require("/src/assets/icons/filter.svg")) }
                                    { t("filters", ns) }
                                </div>
                            ) : <></> }

                            { infoMessage ? (
                                <div class="card-options-menu-item" onclick={() => this.toggleDialog(attrs)}>
                                    { m.trust(require("/src/assets/icons/info_circle.svg")) }
                                    { t("information", ns) }
                                </div>
                            ) : <></> }

                            { downloadableAsImage ? (
                                <div class="card-options-menu-item" onclick={() => attrs.downloadCardAsImage()}>
                                    { m.trust(require("/src/assets/icons/download.svg")) }
                                    { t("downloadAsImage", ns) }
                                </div>
                            ) : <></> }

                            { downloadableAsCsv ? (
                                <div class="card-options-menu-item" onclick={() => attrs.downloadCardAsCsv()}>
                                    { m.trust(require("/src/assets/icons/download.svg")) }
                                    { t("downloadAsCsv", ns) }
                                </div>
                            ) : <></> }
                        </div>
                    </div>
                ) : <></> }
            </div>
        )
    }
}
